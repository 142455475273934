.layout-drawer {
    height: 100%;
    background-color: white;
    display: flex;
    transition: all 0.3s ease-in-out;
    width: 16px;
    .layout-drawer-header {
        width: 0;
        overflow: hidden;
    }

    &.reverse {
        flex-direction: row-reverse;

        .layout-drawer-puller {
            box-shadow: -2px 0 2px #d2d2d2;
            .layout-drawer-arrow {
                transform: rotate(-90deg);
            }
        }
        &.drawer-open {
            .layout-drawer-puller {
                .layout-drawer-arrow {
                    transform: rotate(90deg) !important;
                }
            }

        }
    }


    &.layout-drawer-open {
        width: 300px;
        .layout-drawer-header {
            width: 100%;
        }

        .layout-drawer-puller {
            .layout-drawer-arrow {
                transform: rotate(-90deg) !important;
            }
        }
    }
}

.layout-drawer-title {
    margin: 1.5rem 0 1.5rem 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 21px;
    color: #18b9c2;
    display:flex;
    justify-content: space-between;
}

.layout-drawer-title .close-btn {
    padding: 0.7rem 0;
    display: block;
    position: relative;
    cursor: pointer;
}

.layout-drawer-puller {
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fafafa;
    box-shadow: 2px 0 2px #d2d2d2;
    cursor: pointer;
    height: 100%;
    padding-left: 6px;
}

.layout-drawer-puller .layout-drawer-arrow {
    top: 50%;
    left: -3px;
    position: relative;
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #10b7bf;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

@primary-color: #2FACB2;@border-color-base: @primary-color;@modal-header-bg: @primary-color;@modal-heading-color: #FFF;@modal-close-color: #FFF;@base-border-radius: 4px;