.layout-drawer-menu {
    overflow: scroll;
    height: calc(95% - 20px);
}

.layout-drawer-item-list {
    font-size: 16px;
    font-weight: bold;
    animation-duration: 50ms;
    list-style-type: none;
    height: 100%;
    overflow: scroll;
    padding-bottom: 3rem;
    li {
        padding-bottom: 0.5rem;
        a {
            display: block;
            border-radius: 0.5rem;
            padding: 1rem;
            text-decoration: none;
            color: #052A2C;
            &:hover{
                cursor: pointer;
                background-color: rgba(24, 185, 194, 0.1);
            }

            &.layout-drawer-item-active {
                background-color: #18b9c2;
                color: white;
            }
        }
    }

    .active {
        color: #18b9c2;
        font-size: 18px;
    }
}

@primary-color: #2FACB2;@border-color-base: @primary-color;@modal-header-bg: @primary-color;@modal-heading-color: #FFF;@modal-close-color: #FFF;@base-border-radius: 4px;