.icon-stage-status-clipboard {
  background: url('../common/icons/accomplishment.svg') no-repeat;
  content: '';
  float: left;
  height: 28px;
  width: 32px;
}

.react-spinner {
    display: inline-block;
    position:relative;
    left:40%;
}

.message-icon-dashboard {
  background: url('../common/icons/mDash.png') no-repeat;
  background-size: 35px;
  background-position: center;
  width: 35px;
  float: left;
  height: 30px;
  margin-right: 5px;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}
