.error-boundry-wrap {
    padding: 3% 2%;
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: not-allowed;
    background-color: #00000085;
    .error-description {
        max-height: 450px;
        width: 100%;
        overflow: auto;
        padding: 10px;
        background-color: #eee;
        border: none;
        border-radius: 0 0 10px 10px;
    }
        button.error-reload-button {
        font-size: 15px;
        border: 1px solid #555;
        background-color: #fff;
        padding: 5px 15px;
        border-radius: 5px;
    }
    .copy-msg {
        margin-left: 20px;
        font-size: 14px;
        color: #008300;
    }
    h2 {
        background-color: #fff;
        color: #1c98ad !important;
        text-align: center;
        font-weight: bold !important;
        border-radius: 10px;
        max-width: 50vw;
        font-size: medium;
        height: 50%;
        padding-top: 3% !important;
        margin: auto !important;
    }
    i {
        color: gray;
    }
}

@primary-color: #2FACB2;@border-color-base: @primary-color;@modal-header-bg: @primary-color;@modal-heading-color: #FFF;@modal-close-color: #FFF;@base-border-radius: 4px;