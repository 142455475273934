header.sweft-header {
    top: 0;
    width: 100%;
    height: 64px;
    background-color: #edefef;
    transition: all .3s ease;

    ul {
        float: right;
        margin: 1rem 0.8rem;
        display: inline-flex;

        li {
            padding: 10px 17px;

            a {
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                font-size: 1rem;
                color: #fff;
            }
        }
    }
}

.header-title {
    float: left;
    margin: 1.8rem 1.8rem;

    h2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        color: #18b9c2;
        text-transform: uppercase;
        font-size: 2.4rem;
        margin: 0;
    }

    .child-page-spacer {
        padding: 0 1rem;
    }
}

@primary-color: #2FACB2;@border-color-base: @primary-color;@modal-header-bg: @primary-color;@modal-heading-color: #FFF;@modal-close-color: #FFF;@base-border-radius: 4px;