.left-nav-container {

    overflow-y: auto;
    overflow-x: hidden;


    .span-user-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        vertical-align: text-bottom;
        overflow: hidden;
        display: inline-block;
    }

    .user-details {
        &:hover {
            &::before {
                content: '';
                position: fixed;
                width: 100px;
                height: 100px;
                left: 0;
                top: 15px;
                background: transparent;
                z-index: 99999;
            }

            .dropdown-menu {
                display: block !important;
                left: 95px;
                top: 45px;
            }

        }
    }

    .menuList {
        display: flex;
        flex-direction: column;
        margin: 0;

        li {
            text-align: center;
            transition: transform .2s;
            padding: 1.1rem;

            &:hover {
                transform: scale(1.2);
            }

            i {
                background-position: center;
            }
        }

        .active {
            background-color: #2FACB2;
            color: #fff;

            a {
                color: #fff;
            }

            .icon-dashboard {
                background: url('./images/GlobeIconWhite.svg') no-repeat center;
            }

            .icon-product {
                background: url('./images/ProductIconWhite.svg') no-repeat center;
            }

            .icon-samples {
                background: url('./images/samples-white.png') no-repeat center;
                background-size: 35px;
            }

            .icon-marketing {
                background: url('./images/MarketingIconWhite.svg') no-repeat center;
            }

            .icon-creative {
                background: url('./images/CreativeIconWhite.svg') no-repeat center;
            }

            .icon-publication {
                background: url('./images/PublicationIconWhite.svg') no-repeat center;
            }

            .icon-copywriting {
                background: url('./images/PublicationIconWhite.svg') no-repeat center;
            }

            .icon-purchasing {
                background: url('./images/PublicationIconWhite.svg') no-repeat center;
            }

            .icon-admin {
                background: url('./images/AdminIconWhite.svg') no-repeat center;
            }

            .icon-superadmin {
                background: url('./images/SuperAdminWhite.svg') no-repeat center;
                background-size: 45px;
            }
        }

        .admin {
            border-top: 1px;
            padding: 1.1rem;
        }
    }
}

@primary-color: #2FACB2;@border-color-base: @primary-color;@modal-header-bg: @primary-color;@modal-heading-color: #FFF;@modal-close-color: #FFF;@base-border-radius: 4px;