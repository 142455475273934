@import '~antd/dist/antd.less';

.ant-modal-close-x {
  display: flex;
  align-items: center;
}
.anticon {
  vertical-align: baseline;
}

.ant-alert.ant-alert-error {
  overflow: scroll;
}
.prefilter-container .ant-alert.ant-alert-error {
  overflow: auto !important;
}

.dropdown-filter-select {
  .ant-select-selector {
    border-radius: 5px !important;
  }
}

#gridModal {
  .set-fixed-width {
    width: 22% !important;
    height: 75%;
    overflow: hidden;
    overflow-y: scroll;
    float: left;
  }

  .hero-image-modal {
    width: 78%;
    float: left;
  }
}

#Grid-relationship-editor-nested-popup, #BulkEdit-relationship-editor-nested-popup {
  .ant-select-tree-treenode-disabled {
    background-color: #e1eeee;
    .ant-select-tree-title {
      color: black !important;
    }
    .anticon-caret-down {
      margin-top: 8px;
    }
  }
}

.ant-steps-item-icon .ant-steps-icon {
  top: 6px !important;
}

@primary-color: #2FACB2;@border-color-base: @primary-color;@modal-header-bg: @primary-color;@modal-heading-color: #FFF;@modal-close-color: #FFF;@base-border-radius: 4px;